import Web3 from "web3";
import axios from "axios";
import {userLike, userUnlike, userVote, userUnvote} from "../api/user"
import {disabled} from "yarn/lib/cli";
$(document).ready(function(){
	if (!$('.header__action-btn--signin').length > 0 && !(localStorage.getItem('from_devise') == 'true')) {
		let network = $('#main_network_name').text();
		let check_network = $('#main_network_name').text() == 'ETH' ? 'ETHEREUM' : 'MATIC'
		if(localStorage.getItem('from_venly') == 'true' && check_network != localStorage.getItem('secretType')){
			let service = localStorage.getItem('service')
			connectVenly(service, network)
		}else if(!localStorage.getItem('from_venly') && window.web3 && window.web3.eth) {
			checkNetwork();
		}
	}
	if ($('.currency-symbol').length > 0)
		$('.currency-symbol').tooltip();

	window.addEventListener("ajax:before", (e) => {
		$(".loading-gif").show();
		$('body').css('overflow','hidden');
	});

	window.addEventListener("ajax:complete", (e) => {
		$(".loading-gif").hide();
		$('body').css('overflow','auto');
	});

	$(document).on("change", ".localeChange", function () {
		window.location.href = "/?locale=" + $(".localeChange option:selected").val()
	})

	$('#header-carousel').owlCarousel({
		loop: true,
		margin: 10,
		dots: false,
		nav: true,
		autoplay:true,
		lazyLoad: true,
		autoplayTimeout:3000,
		autoplayHoverPause:true,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 1
			},
			1000: {
				items: 1
			}
		}
	});

	function readURL(input, previewId) {
	    if (input.files && input.files[0]) {
	        var reader = new FileReader();
	        reader.onload = function(e) {
	            $(previewId).css('background-image', 'url('+e.target.result +')');
	            $(previewId).hide();
	            $(previewId).fadeIn(650);
	        }
	        reader.readAsDataURL(input.files[0]);
	    }
	}
	
	$(document).on('change', '#imageUpload', function() {
	  readURL(this, '#imagePreview');
	});

	$(document).on('change', '#bannerUpload', function() {
		readURL(this, '#bannerPreview');
	});

	function readURLSingle(input, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, coverImg) {

		console.log('DEBUG')
		console.log('input', input)
		console.log('file', file)
		console.log('previewSection', previewSection)
		console.log('imagePreview', imagePreview)
		console.log('closePreviewBtn', closePreviewBtn)
		console.log('placeholder', placeholder)
		console.log('fileId', fileId)
		console.log('chooseFile', chooseFile)
		console.log('coverImg', coverImg)
		
		var splFile = file.name.split(/[ .]+/);

		console.log('splFile', splFile)

		var splType = splFile[splFile.length - 1];

		console.log('splType', splType)

		var ftype = (('vox' == splType) || ('vxm' == splType) || ('vxr' == splType) || ('gltf' == splType) || ('application/zip' == splType)) ? splType : file.type;

		console.log('ftype', ftype)

		var fsize = file.size / 1024 / 1024; // in MBs
    	if (fsize > 64) {
			return toastr.error('Invalid file size!. Must be less than 64MB');
		}
		var imgExt = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];
		var audExt = ['audio/mp3', 'audio/webm', 'audio/mpeg'];
		var vidExt = ['video/mp4', 'video/webm'];
		var splExt = ['vox', 'vxm', 'vxr', 'gltf', 'application/zip', 'application/x-zip-compressed'];
		if (input.files && input.files[0]) {
			var reader = new FileReader();

			reader.onload = function(e) {

				console.log('1 imgExt.includes(ftype)')
				console.log(imgExt.includes(ftype))
				console.log('2 coverImg')
				console.log(coverImg)
				console.log('3 audExt.includes(ftype)')
				console.log(audExt.includes(ftype))
				console.log('4 vidExt.includes(ftype)')
				console.log(vidExt.includes(ftype))
				console.log('5 splExt.includes(ftype)')
				console.log(splExt.includes(ftype))


				if (imgExt.includes(ftype)) {
					previewSection.css('background-image', 'url('+e.target.result +')');
					previewSection.css({ 
						'width': '100%', 
						'height': '225px',
						'border-radius': '15px', 
						'background-size': 'cover',
						'background-repeat': 'no-repeat',
						'background-position': 'center',
						'margin-left': 'auto',
						'margin-right': 'auto',
					});
					previewSection.hide();
					previewSection.fadeIn(650);
					imagePreview.css('background-image', 'url('+e.target.result +')');
					imagePreview.css({ 'height': '225px' });
				} else if (coverImg && !ftype == 'video/mp4') {
					return toastr.error('Invalid file type!');
				} else if (audExt.includes(ftype)) {
					$('.coverUpload').removeClass("hide");
					$('#file-2').prop('required', true);
					previewSection.hide();
					previewSection.fadeIn(650);
					imagePreview.html('<audio width="300" height="300" controls><source src="mov_bbb.mp4" id="audio_here"> </audio>');
					imagePreview.css({ 'height': '55px' });
					$('#audio_here')[0].src = URL.createObjectURL(input.files[0]);
					$('#audio_here').parent()[0].load();
				} else if (vidExt.includes(ftype)) {
					$('.coverUpload').removeClass("hide");
					$('#file-2').prop('required', true);
					previewSection.hide();
					previewSection.fadeIn(650);
					imagePreview.html('<video width="300" height="200" controls><source src="mov_bbb.mp4" id="video_here"> </video>');
					previewSection.html('<video width="300" height="200" controls><source src="mov_bbb.mp4" id="video_here2"> </video>');
					imagePreview.css({ 'height': '225px' });
					previewSection.css({ 'height': '225px' });
					var vid_url = URL.createObjectURL(input.files[0]);
					$('#video_here')[0].src = vid_url;
					$('#video_here').parent()[0].load();
					$('#video_here2')[0].src = vid_url;
					$('#video_here2').parent()[0].load();
				} else if (splExt.includes(ftype)) {
					$('.coverUpload').removeClass("hide");
					$('#file-2').prop('required', true);
					previewSection.hide();
					previewSection.fadeIn(650);
					imagePreview.html('<a href="" id="file_here" target="_blank">'+file.name+'</a>');
					imagePreview.css({ 'background': '#333', 'padding': '10px 20px', 'borderRadius': '5px' });
					closePreviewBtn.css('display', 'inline-block');
					$('#file_here').attr("href", URL.createObjectURL(input.files[0]));
				} else {
					return toastr.error('Invalid file type!');
				}
				imagePreview.css({ 
					'width': '300px', 
					'background-size': 'cover',
					'background-repeat': 'no-repeat',
					'background-position': 'center',
					'margin-left': 'auto',
					'margin-right': 'auto',
					'border-radius': '15px'
				});
				closePreviewBtn.css('display', 'inline-block');
				placeholder.fadeOut(100);
				fileId.fadeOut(100);
				chooseFile.fadeOut(100);
				imagePreview.hide();
				imagePreview.fadeIn(650);
			}

			reader.readAsDataURL(input.files[0]);
		}
	}

	$(document).on('change', '#file-1', function(e) {
		var file = e.currentTarget.files[0];
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes');
		var closePreviewBtn = $('#close-preview-button');
		var placeholder = $('#placeholder');
		var fileId = $('#file-1');
		var chooseFile = $('#choose_file_selected');
		console.log('asset')
		readURLSingle(this, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, false);
	});

	$(document).on('change', '#file-2', function(e) {
		console.log('preview')

		var file = e.currentTarget.files[0];
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes2');
		var closePreviewBtn = $('#close-preview-button2');
		var placeholder = $('#placeholder2');
		var fileId = $('#file-2');
		var chooseFile = $('#choose_file_selected2');
		readURLSingle(this, file, previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile, true);
	});

	function closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile) {
		fileId.val('');
		placeholder.fadeIn(100);
		fileId.fadeIn(100);
		chooseFile.fadeIn(100);
		chooseFile.html('Choose file');
		imagePreview.css({ 
			'width': 'auto', 
			'height': 'auto', 
			'background-size': 'cover',
			'background-repeat': 'no-repeat',
			'background-position': 'center',
			'margin-left': 'auto',
			'margin-right': 'auto'
		});
		closePreviewBtn.css('display', 'none');
		imagePreview.css('background-image', 'none');
		$('#imagePreviewRes').css('background', 'none');
		imagePreview.html('');
		previewSection.css('background-image', 'none');
		previewSection.html('');
	}

	$(document).on('click', '#close-preview-button', function(){
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes');
		var closePreviewBtn = $('#close-preview-button');
		var placeholder = $('#placeholder');
		var fileId = $('#file-1');
		var chooseFile = $('#choose_file_selected');
		closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile);
		$('.coverUpload').addClass("hide");
		$('#file-2').prop('required', false);
	});

	$(document).on('click', '#close-preview-button2', function(){
		var previewSection = $('#my-preview-section');
		var imagePreview = $('#imagePreviewRes2');
		var closePreviewBtn = $('#close-preview-button2');
		var placeholder = $('#placeholder2');
		var fileId = $('#file-2');
		var chooseFile = $('#choose_file_selected2');
		closePreview(previewSection, imagePreview, closePreviewBtn, placeholder, fileId, chooseFile);
	});


	$(document).on('click', '#token-maximize', function(){
		$('.token-section').addClass('main-div-js-element');
		$('.display-section-heart-maximize').css('display','none');
		$('.display-section-heart-minimize').css('display','block');
		$('.heading-token-details-mm').css('display','block');
		$('.token-image').addClass('img-div-js-element');
		$('.token-image img').addClass('img-js-element');
		$('.image_get_attachment').addClass('height-auto-token');
	});

	$(document).on('click', '#token-minimize', function(){
		$('.token-section').removeClass('main-div-js-element');
		$('.display-section-heart-maximize').css('display','flex');
		$('.display-section-heart-minimize').css('display','none');
		$('.heading-token-details-mm').css('display','none');
		$('.token-image').removeClass('img-div-js-element');
		$('.token-image img').removeClass('img-js-element');
		$('.image_get_attachment').removeClass('height-auto-token');
	});

	// search input field

	const changeNetwork = async ({ networkName }) => {
		try {
			if (!window.ethereum) throw new Error("No crypto wallet found");
			await window.ethereum.request({
				method: "wallet_addEthereumChain",
				params: [
					{
						chainName: 'Polygon Mainnet',
						chainId: web3.utils.toHex(137),
						nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
						rpcUrls: ['https://polygon-rpc.com/'],
					}
				]
			});
		} catch (err) {
			console.log("error")
		}
	};
	if(window.ethereum){
		window.ethereum.on('networkChanged', function(networkId){
			console.log('networkChanged',networkId);
			if ((networkId == 137 || networkId == 1) && !(parseInt(getCookie('chain_id')) == networkId)){
				console.log("=====================")
				window.location.href = "/change_network/?chain_id="+networkId
			}
		});
	}

	$(document).on('change', '#proposal_currency', function(){
		// Supporting USDC
		try {
			if($(this).val() == 'USDC') {
				var network_name = $('#main_network_name').text().trim();
				if(network_name == 'ETH'){
					return;
				}
			}
		} catch(err){}
		var chain_id = 1;
		if($(this).val() == 'MATIC') {
			chain_id = 137;
		}
		$.ajax({
			url: '/change_network_ajax',
			type: 'GET',
			data: {chain_id: chain_id},
			success: function (resp) {
				console.log(resp.message);
				checkNetwork();
			},
			failure: function (err) {
				console.log(' Cannot update network');
			}
		});
	});

	async function checkNetwork(){
		if((localStorage.getItem('from_devise') == 'true'))
			return false
		if((localStorage.getItem('from_venly')=='true')){
			let check_network = $('#main_network_name').text() == 'ETH' ? 'ETHEREUM' : 'MATIC'
			if(localStorage.getItem('from_venly') == 'true' && check_network != localStorage.getItem('secretType')){
				let service = localStorage.getItem('service')
				connectVenly(service, $('#main_network_name').text())
			}
			return
		}
		if($('.header__action-btn--signin').length > 0)
			return false;
		if (window.web3 && window.web3.eth) {
			var chainId = await web3.eth.getChainId();
			if (chainId == parseInt(getCookie('chain_id'))){
				$(".loading-gif-network").hide();
				loadBalance()
			} else {
				let intended_chain_id = (chainId == 1) ? 137 : 1;
				try {
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: web3.utils.toHex(intended_chain_id) }],
					});
				} catch (err) {
					// This error code indicates that the chain has not been added to MetaMask.
					if (err.code === 4902) {
						await window.ethereum.request({
							method: 'wallet_addEthereumChain',
							params: [
								{
									chainName: 'Polygon Mainnet',
									chainId: web3.utils.toHex(intended_chain_id),
									nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
									rpcUrls: ['https://polygon-rpc.com/'],
								},
							],
						});
					}
				}
				// $(".loading-gif-network").show();
			}
		}
	}

	function loadBalance() {
		if (window.web3 && window.web3.eth) {
			window.updateEthBalance()
		}
	}

	window.clearToastr = async function clearToastr() {
		$('.toast').remove();
	}

	setInterval(function() {
		checkNetwork()
	}, 10000);

	window.show_modal = async function show_modal(id) {
		$.magnificPopup.open({
			closeOnBgClick: false ,
			enableEscapeKey: false,
			items: {
				src: id
			},
			type: 'inline'
		});
	}
	window.getCookie = function getCookie(cname) {
		let name = cname + "=";
		let ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i++) {
		  let c = ca[i];
		  while (c.charAt(0) == ' ') {
			c = c.substring(1);
		  }
		  if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		  }
		}
		return "";
	  }


	$(document).on('click', '.readmore-btn', function(){
		$(".short_content").css("display","none");
		$('.full_content').css("display","block");
		$(this).css("display","none");
		$('.readless-btn').css("display","block");
		var randomCustom = $('#randomCustom').height();
		// $('#sticky-collection').css('height', randomCustom);
	});
	$(document).on('click', '.readless-btn', function(){
		$(".full_content").css("display","none");
		$('.short_content').css("display","block");
		$(this).css("display","none");
		$('.readmore-btn').css("display","block");
		var randomCustom = $('#randomCustom').height();
		// $('#sticky-collection').css('height', randomCustom);
	});

	var randomCustom = $('#randomCustom').height();
	// $('#sticky-collection').css('height', randomCustom);

	$(document).on('click', '.toggle_like', async function(){
		var already_liked = $(this).data('liked');
		var collection_id = $(this).data('collection_id');
		var address = $(this).data('address');
		if(!address) {
			toastr.error('Please connect your wallet to proceed.');
		} else {
			const token = document.querySelector('[name=csrf-token]').content
			var likes_count = parseInt($(this).find('span').text());
			if(already_liked){
				await userUnlike(address, collection_id, token);
				$(this).find('span').text(likes_count - 1);
				$(this).removeClass('is-active');
				$(this).data('liked', false);
			} else {
				await userLike(address, collection_id, token);
				$(this).find('span').text(likes_count + 1);
				$(this).data('liked', true);
				if(!$(this).hasClass('is-active')){
					$(this).addClass('is-active');
				}
			}
		}
	})

	$(document).on('click', '.toggle_vote', async function(){
		var already_voted = $(this).data('voted');
		var collection_id = $(this).data('collection_id');
		var contest_id = $(this).data('contest_id');
		var address = $(this).data('address');
		if(!address) {
			toastr.error('Please connect your wallet to proceed.');
		} else {
			const token = document.querySelector('[name=csrf-token]').content
			console.log(already_voted);
			if(already_voted == 'true' || already_voted == true){
				var unvote_response = await userUnvote(address, collection_id,contest_id, token);
				if(unvote_response){
					$(this).find('span').text('Vote')
					$(this).css('background-color', '#22b0f0')
					$(this).find('span').css('color', 'white')
					$(this).data('voted', false);
				}
			} else {
			 	var vote_response = await userVote(address, collection_id, contest_id, token);
				if(vote_response){
					$(this).find('span').text('UnVote')
					$(this).css('background-color', 'white')
					$(this).css('border', '1px solid #22b0f0')
					$(this).find('span').css('color', '#22b0f0')
					$(this).data('voted', true);
				}
			}
		}
	})


	async function handleProposalBudgetKeyUp() {
		try {
			const currentVal = parseFloat($('#proposal_budget').val());
			const params = {
				role: 'owner', // Add your parameters here
			};
			 await axios.get('/builds/proposal_tax_mutilplier', { params })
				.then(response => {
					if (!isNaN(currentVal)) {
						const sandstormFee = ((response.data['rate'] / 100) * currentVal);
						const calVal = currentVal + sandstormFee;
						$('#sandstorm_fee_preview').text(`${sandstormFee} ${$('#proposal_currency').val()}`);
						$('#total_charge_preview').text(`${calVal} ${$('#proposal_currency').val()}`);
					}
				})
				.catch(error => {
					$('#sandstorm_fee_preview').text(`--`);
					$('#total_charge_preview').text(`Error fetching tax rate`);
					$('#total_charge_preview').css('color', 'red')
				});

		} catch (err) {
			console.log(err.message);
		}
	}

	$(document).on('keyup', '#proposal_budget', handleProposalBudgetKeyUp);

	async function handleapplicantBidtKeyUp() {
		try {
			const current_val = parseFloat($('#applicant_bid').val());
			const params = {
				role: 'builder', // Add your parameters here
			};
			await axios.get('/builds/proposal_tax_mutilplier', { params })
				.then(response => {
					if (!isNaN(current_val)) {
						const sandstormFee = ((response.data['rate'] / 100) * current_val);
						const cal_val = current_val - sandstormFee;
						$('#sandstorm_mgmnt_fee_preview').text(sandstormFee + ' ' + $('#applicant_currency').val());
						$('#total_charge_mgmnt_preview').text(cal_val + ' ' + $('#applicant_currency').val());
					}
				})
				.catch(error => {
					$('#sandstorm_mgmnt_fee_preview').text(`--`);
					$('#total_charge_mgmnt_preview').text(`Error fetching tax rate`);
					$('#total_charge_mgmnt_preview').css('color', 'red')
				});
		} catch (err) {
			console.log(err.message);
		}
	}

	$(document).on('keyup', '#applicant_bid', async function () {
		await handleapplicantBidtKeyUp();
	});

	$(document).on('click', '.invite_btn', function (){
		var proposal_id = $(this).data('proposal_id');
		var user_id = $(this).data('user_id');
		var btn = $(this);
		$.post('/send_invitation.json', { user_id:user_id, proposal_id: proposal_id }, function(response){
			if(response.status == 'ok'){
				btn.text("invited");
				btn.prop('disabled', true);
				btn.addClass('invite-disable-btn')
				// btn.css("color", "yellow");
				toastr.success('Invitation sent to ' + response.name)
			}
		});
	});
});
