// direct_uploads.js

addEventListener("direct-upload:initialize", event => {
    if (event && event.target && event.target.id === "file-1") {
        const { detail } = event
        const { id, file } = detail

        const target = document.getElementById(`direct-upload-progress-parent`)
        
        while (target.firstChild) {
            target.removeChild(target.lastChild);
        }

        target.innerHTML = `
            <div class="direct-upload direct-upload--pending">
                <div id="direct-upload-progress-bar" class="direct-upload__progress" style="width: 0%"></div>
                <span class="direct-upload__filename">${file.name}</span>
            </div>
        `
    }
    
  })
  
  addEventListener("direct-upload:start", event => {
    if (event && event.target && event.target.id === "file-1") {
        const target = document.getElementById(`direct-upload-progress-parent`)
        target.classList.remove("direct-upload--pending")
    }
  })
  
  addEventListener("direct-upload:progress", event => {
    if (event && event.target && event.target.id === "file-1") {
        const { id, progress } = event.detail
        const progressElement = document.getElementById(`direct-upload-progress-bar`)
        progressElement.style.width = `${progress}%`
    }
  })
  
  addEventListener("direct-upload:error", event => {
    if (event && event.target && event.target.id === "file-1") {
        event.preventDefault()
        const { id, error } = event.detail
        const element = document.getElementById(`direct-upload-progress-bar`)
        element.classList.add("direct-upload--error")
        element.setAttribute("title", error)
    }
  })
  
  addEventListener("direct-upload:end", event => {
    console.log('DONE', event)
    
    if (event && event.target && event.target.id === "file-1") {
        const { id } = event.detail
        const element = document.getElementById(`direct-upload-progress-parent`)
        element.classList.add("direct-upload--complete")
    }
  })